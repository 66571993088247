;(function() {
  'use strict'

  subscriberService.$inject = ["$q", "Money", "api", "subscribersResource", "plansService", "hostingCreditService"];
  angular
    .module('core.services')
    .factory('subscriberService', subscriberService)

  /* @ngInject */
  function subscriberService(
    $q,
    Money,
    api,
    subscribersResource,
    plansService,
    hostingCreditService
  ) {
    return {
      create: create,
      get: get,
      getAll: getAll,
      save: save,
      setCurrency: setCurrency,
      addCredit: addCredit,
      togglePaymentType: togglePaymentType,
    }

    /**
     * @name create
     * @description create a new subscriber
     * @param {{}} subscriberData subscriber object
     * @return {Promise} promise will resolve on creation success
     */
    function create(subscriberData) {
      return $q(function(resolve, reject) {
        // hack to make phoneNumber optional even though
        // it's required by BE.
        if (!subscriberData.phoneNumber || subscriberData.phoneNumber === '0') {
          subscriberData.phoneNumber = '(' + subscriberData.name + ')'
        }
        subscribersResource
          .createSubscriberOnly(subscriberData)
          .success(function(response) {
            subscriberData.id = response.id
            resolve(subscriberData)
          })
          .error(reject)
      }).then(transitionPlan)
    }

    /**
     * @name get
     * @description get a subscriber resource by ID
     * @param {String} subscriberId subscriber ID
     * @return {Promise} promise to resolve a subscriber resource
     */
    function get(subscriberId) {
      return $q(function(resolve, reject) {
        subscribersResource
          .get({ id: subscriberId })
          .success(function(x, subscriber) {
            prepareSubscriberFromBE(subscriber)
            var loadHostingCredit = hostingCreditService
              .load(subscriber.id)
              .then(function(hostingCredit) {
                subscriber.hostingCredit = hostingCredit
              })
            var loadPlan = plansService
              .get(subscriber.planDetail.planId)
              .then(function(plan) {
                subscriber.plan = plan
              })
            $q.all([loadHostingCredit, loadPlan]).then(function() {
              resolve(subscriber)
            })
          })
          .error(reject)
      })
    }

    /**
     * @name getAll
     * @description get list of subscriber resources
     * @return {Promise} promise to resolve subscriber resources
     */
    function getAll(options) {
      var deferred = $q.defer()

      options = options || {}

      subscribersResource
        .get(options)
        .success(function(x, subscribers) {
          _.each(subscribers, prepareSubscriberFromBE)
          deferred.resolve(subscribers)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    function prepareSubscriberFromBE(subscriber) {
      subscriber.contentGroupIdsPrevious = _.clone(subscriber.contentGroupIds)

      subscriber.data = _.isPlainObject(subscriber.data) ? subscriber.data : {}
      subscriber.data.flags = _.isPlainObject(subscriber.data.flags)
        ? subscriber.data.flags
        : {}

      // HACK: subscribers shouldn't have threshold/unpaidBalance/credit
      // before they pick a currency but we need to keep to the model,
      // so we fallback to AUD and update them when the subscriber
      // sets their currency
      var currency = subscriber.billing.currency || 'AUD'

      subscriber.billing = {
        paymentType: subscriber.billing.paymentType,
        currency: subscriber.billing.currency,
        threshold: new Money({
          amount: subscriber.billing.threshold,
          currency: currency,
        }),
        unpaidBalance: new Money({
          amount: subscriber.billing.thresholdBalance,
          currency: currency,
        }),
        credit: new Money({
          amount: subscriber.billing.accountBalance,
          currency: currency,
        }),
        balance: new Money({
          amount: _.clamp(
            subscriber.billing.accountBalance -
              subscriber.billing.thresholdBalance,
            0,
            Infinity
          ),
          currency: currency,
        }),
      }
    }

    function save(subscriber) {
      return $q(function(resolve, reject) {
        // hack to make phoneNumber optional even though
        // it's required by BE.
        subscriber = _.cloneDeep(subscriber)
        if (!subscriber.phoneNumber || subscriber.phoneNumber === '0') {
          subscriber.phoneNumber = '(' + subscriber.name + ')'
        }
        subscriber.billing = {
          threshold: subscriber.billing.threshold.amount,
        }
        subscribersResource
          .save(subscriber)
          .success(function() {
            transitionPlan(subscriber)
              .then(updateContentGroups)
              .then(function() {
                resolve(subscriber)
              })
              .catch(reject)
          })
          .error(reject)
      })
    }

    function setCurrency(subscriber, code) {
      return $q(function(resolve, reject) {
        subscribersResource
          .setCurrency({ id: subscriber.id, currency: code })
          .success(function(response) {
            subscriber.billing.currency = code
            subscriber.billing.threshold.currency = code
            subscriber.billing.unpaidBalance.currency = code
            subscriber.billing.credit.currency = code
            resolve(response)
          })
          .error(reject)
      })
    }

    function transitionPlan(subscriber) {
      return $q(function(resolve, reject) {
        // Created with no plan selection. BE defaults to Free
        if (!subscriber.plan) {
          return resolve(subscriber)
        }
        if (subscriber.planDetail.planId === subscriber.plan.id) {
          return resolve(subscriber)
        }
        subscribersResource
          .assignPlan({ planId: subscriber.plan.id, id: subscriber.id })
          .success(function() {
            subscriber.planDetail.planId = subscriber.plan.id
            resolve(subscriber)
          })
          .error(reject)
      })
    }

    function updateContentGroups(subscriber) {
      return $q(function(resolve, reject) {
        var current = subscriber.contentGroupIds
        var previous = subscriber.contentGroupIdsPrevious
        var changed = !_.isEqual(_.orderBy(current), _.orderBy(previous))
        if (!changed) {
          return resolve(subscriber)
        }
        subscribersResource
          .updateContentGroups({
            id: subscriber.id,
            contentGroupIds: current,
          })
          .success(function() {
            subscriber.contentGroupIdsPrevious = _.clone(
              subscriber.contentGroupIds
            )
            resolve(subscriber)
          })
          .error(reject)
      })
    }

    function addCredit(subscriberId, amount, complementaryAmount) {
      return api.subscribers.addCredit({
        id: subscriberId,
        amount: amount,
        complementaryAmount: complementaryAmount,
      })
    }

    function togglePaymentType(subscriber) {
      return $q(function(resolve, reject) {
        var current = subscriber.billing.paymentType
        var next = current === 'CREDITCARD' ? 'INVOICE' : 'CREDITCARD'
        subscriber.billing.paymentType = next
        subscribersResource
          .updatePaymentType({ id: subscriber.id, type: next })
          .success(function() {
            resolve()
          })
          .error(function() {
            subscriber.billing.paymentType = current
            reject()
          })
      })
    }
  }
})()
